<template>
  <div class="w-full flex flex-col">
    <h3 class="text-start mt-4 text-xl">Entregas</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Rutas de Entrega</h4>
    <section class="w-full bg-white p-2">
      <div class="w-full flex justify-between">
        <label>Filtro</label>
        <button @click.stop="handleToggle">
          <minus-icon v-if="isToggle" class="w-4 h-4" />
          <plus-icon v-else class="w-4 h-4" />
        </button>
      </div>
      <div class="w-full flex flex-col">
        <b-form @submit="applyFilters">
          <div>
            <div class="flex grid grid-cols-4 gap-4 mt-4">
              <input-field
                class="mx-2"
                idInput="from-date"
                idFormGroup="fromDate-group"
                type="date"
                label="Desde"
                v-model="filterForm.keyWord"
              />
              <input-field
              idInput="to-date"
              idFormGroup="toDate-group"
              type="date"
              label="Hasta"
              v-model="filterForm.keyWord"
            />
            </div>
          </div>
        </b-form>
      </div>
    </section>
    <section class="w-full bg-white p-2 mt-4">
      <div class="w-full flex justify-start">
        <label class="mb-4">Entregas</label>
      </div>
      <table-delivery-route :packages="packages"/>
    </section>
  </div>
</template>

<script>
import InputField from '@/components/InputField.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TableDeliveryRoute from '../../components/TableDeliveryRoute.vue';

export default {
  name: 'DeliveryRoute',
  components: {
    PlusIcon, MinusIcon, InputField, TableDeliveryRoute,
  },
  data() {
    return {
      isToggle: true,
      filterForm: {
        keyWord: '',
        filterBySelect: null,
        operatorSelect: null,
        client: null,
        agent: null,
        state: '',
        payment: '',
        sortBy: null,
        office: null,
      },
      clientListOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Alejandro Gonzalez', value: 'alejandro_gonzalez' },
      ],
      packages: [
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
        {
          gvt: 'GVY108430',
          agent: '',
          client: 'Manuel Garro Campos',
          state: 'Procesando',
          payment: 'Pendiente',
          invoice: '$13.4',
        },
      ],
    };
  },
  methods: {
    handleToggle() {
      this.isToggle = !this.isToggle;
    },
    applyFilters() {
      // Logic here
    },
  },
};
</script>

<style scoped>
</style>
